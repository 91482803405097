.main {
  display: flex;
  min-height: 100vh;
  padding-top: 60px;
  background-color: var(--second-white);
}

.landing-sidebar {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.general-sidebar {
  color: var(--text-color) !important;
  background-color: var(--background) !important;
}
