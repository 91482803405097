.content {
  max-width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 0 65px;
  min-height: calc(100vh - 60px);

  @media (min-width: 961px) {
    padding: 0 0 0 70px;
  }
}

.landing-sidebar {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.general-sidebar {
  color: var(--text-color) !important;
  background-color: var(--background) !important;
}
